<template>
  <div>
    <banner
      bold-title="Nueva"
      type="banner--admin"
      title=" Propuesta"
      :options="{ padding: '30px' }"
      description="Formulario para la incripción de trabajo de Innovación"
    ></banner>

    <div class="d-flex px-7 py-5">
      <div class="col-12 d-flex justify-end">
        <v-btn
          color="primary"
          class="white--text text-none elevation-0"
          @click="back"
          dark
        >
          Regresar
        </v-btn>
      </div>
    </div>

    <v-stepper
      class="elevation-0 px-7"
      v-model="step"
      vertical
      v-show="step !== 8 && step !== -1"
    >
      <v-stepper-step :complete="step > 1" step="1" color="primary">
        <strong class="primary--text">
          Información acerca de la entidad o consorcio del participante
        </strong>
      </v-stepper-step>
      <v-stepper-content step="1">
        <step-one @nextStep="nextStep"></step-one>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2" color="primary">
        <strong class="primary--text">
          Información sobre el equipo de trabajo
        </strong>
      </v-stepper-step>
      <v-stepper-content step="2">
        <step-two @nextStep="nextStep"></step-two>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3" color="primary">
        <strong class="primary--text">
          Información sobre la propuesta 1 de 3
        </strong>
      </v-stepper-step>
      <v-stepper-content step="3">
        <step-three @nextStep="nextStep"></step-three>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4" color="primary">
        <strong class="primary--text">
          Información sobre la propuesta 2 de 3
        </strong>
      </v-stepper-step>
      <v-stepper-content step="4">
        <step-four @nextStep="nextStep"></step-four>
      </v-stepper-content>

      <v-stepper-step :complete="step > 5" step="5" color="primary">
        <strong class="primary--text">
          Información sobre la propuesta 3 de 3
        </strong>
      </v-stepper-step>
      <v-stepper-content step="5">
        <step-five @nextStep="nextStep"></step-five>
      </v-stepper-content>

      <v-stepper-step :complete="step > 6" step="6" color="primary">
        <strong class="primary--text"> Documentación Soporte </strong>
      </v-stepper-step>
      <v-stepper-content step="6">
        <step-six @nextStep="nextStep"></step-six>
      </v-stepper-content>

      <v-stepper-step :complete="step > 7" step="7" color="primary">
        <strong class="primary--text">
          Declaración de representante legal proponente de la innovación
        </strong>
      </v-stepper-step>
      <v-stepper-content step="7">
        <step-seven @nextStep="nextStep"></step-seven>
      </v-stepper-content>
    </v-stepper>

    <successful-proposal v-if="step===8"></successful-proposal>
  </div>
</template>
<script>
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import StepThree from './components/StepThree'
import StepFour from './components/StepFour'
import StepFive from './components/StepFive'
import StepSix from './components/StepSix'
import StepSeven from './components/StepSeven'
import Banner from '@/components/banner/Banner'
import eventHub from '@/eventhub'
import loaderMixin from '@/mixins/loaderMixin'
import dateMixin from '@/mixins/dateMixin'
import { AWARD_PROPOSALS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import SuccessfulProposal from '@/views/private/award-proposal/components/SuccessfulProposal'

export default {
  name: 'AwardProposals',

  created() {
    this.awardProposalId = this.$route.params.id
    if (this.awardProposalId) {
      this.getAwardProposal()
    } else {
      this.step = 1
    }
  },

  data: () => ({
    awardProposalId: null,
    step: -1
  }),

  methods: {
    async getAwardProposal() {
      const response = await this.get(
        AWARD_PROPOSALS_URL + '/' + this.awardProposalId
      )
      const awardProposal = response.data
      this.awardProposalId = awardProposal.id
      this.step = awardProposal.currentStep
    },

    nextStep(step) {
      this.step = step
    },

    back() {
      this.$router.push({
        name: 'awardProposals'
      })
    }
  },

  watch: {
    step: (newValue) => {
      eventHub.$emit(`step${newValue}`)
    },
  },

  mixins: [loaderMixin, crudServiceMixin, dateMixin],

  components: {
    SuccessfulProposal,
    StepFive,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepSix,
    StepSeven,
    Banner
  }
}
</script>
