<template>
  <div>
    <p class="font-bold">
      NOTA: Si desea suspender la redacción, dé clic en "Guardar avance" antes
      de abandonar el formulario para que su avance sea registrado.
      Mientras no haya enviado su propuesta, siempre puede volver al paso anterior
      para editar o continuar escribiendo.
    </p>
    <p>
      Tenga en cuenta ingresar los nombres como deben aparecer en los medios de
      difusión
    </p>
    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':10}"
          name="Título"
          vid="title"
        >
          <v-text-field
            v-model="model.title"
            :error-messages="errors"
            counter="10"
            dense
            label="23. Nombre de propuesta o proyecto *"
            outlined
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter
                v-bind="props"
                :value="
                  model.title === undefined
                    ? ''
                    : model.title.trim().split(/[\s\n ]+/).length
                "
              ></v-counter>
            </template>
          </v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress}"
          name="Categoría"
          vid="category"
        >
          <p class="mt-4">24. Línea temática de la postulación * (Elija sólo una)</p>
          <v-radio-group v-model="model.category" :error-messages="errors" row>
            <v-radio label="Soluciones amigables" value="SA"></v-radio>
            <v-radio label="Movilidad eléctrica" value="ME"></v-radio>
            <v-radio label="Nuevos modelos de negocios digitales" value="NM"></v-radio>
          </v-radio-group>
        </ValidationProvider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{'required':!isSaveProgress,'maxWords':100}"
              name="Descripción"
              vid="summary"
            >
              <v-textarea
                v-model="model.summary"
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                class="mt-6"
                counter="100"
                dense
                label="25. Información de la propuesta * (100 palabras)"
                outlined
                type="text"
              >
                <template v-slot:counter="{ props }">
                  <v-counter
                    v-bind="props"
                    :value="
                      model.summary === undefined
                        ? ''
                        : model.summary.trim().split(/[\s\n ]+/).length
                    "
                  ></v-counter>
                </template>
              </v-textarea>
            </ValidationProvider>
          </template>
          <span>
            Resumen del trabajo de innovación para ser usado con fines de
            difusión (en caso de ser seleccionado).
          </span>
        </v-tooltip>
      </v-form>
    </ValidationObserver>
    <v-btn
      class="elevation-0 text-none"
      color="primary"
      dark
      @click="$emit('nextStep', 2)"
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="#ffc200"
      dark
      @click="next"
    >Guardar avance
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      dark
      @click="save"
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        :href=termsAndConditionsUrl
        target="_blank"
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL, TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepThree',
  async created() {
    eventHub.$on('step3', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => { await this.getStep3() })
    })
  },

  data() {
    return {
      awardProposalId: null,
      model: {
        awardProposalId: null,
        title: '',
        category: '',
        summary: ''
      },
      saveProgress: false,
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },

  computed: {
    isSaveProgress() {
      return this.saveProgress
    }
  },

  methods: {
    async getStep3() {
      if (this.awardProposalId) {
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-3/${this.awardProposalId}`)
        this.model = response.data
      }
    },

    async next() {
      this.saveProgress = true
      this.$nextTick(() => {
        this.submit()
      })
    },

    async save() {
      this.saveProgress = false
      this.$nextTick(() => {
        this.submit()
      })
    },

    async submit() {
      let result = false
      if (!this.saveProgress) {
        result = await this.$refs.form.validate()
      } else {
        result = true
      }
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.post(`${AWARD_PROPOSALS_URL}/step-3?saveProgress=${this.saveProgress}`, this.model)
          .then(() => {
            this.hideLoader()
            if (!this.saveProgress) {
              this.$emit('nextStep', 4)
            } else {
              this.showSuccess('Avance guardado correctamente!')
            }
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },
  },

  mixins: [loaderMixin, crudServiceMixin, notificationMixin]
}
</script>
