<template>
  <div>
    <p class="font-bold">
      NOTA: Si desea suspender la redacción, dé clic en "Guardar avance" antes
      de abandonar el formulario para que su avance sea registrado.
      Mientras no haya enviado su propuesta, siempre puede volver al paso anterior
      para editar o continuar escribiendo.
    </p>

    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">

        <p>
          <strong>28. Impacto Social * (500 palabras)</strong><br>
          Explicar de qué manera el proyecto alcanza sostenibilidad a nivel social o logra mitigar impactos sociales
          negativos, cierre de brechas en los altos índices de pobreza y escasez educativa, inclusión social, equidad,
          oportunidades de género, entre otras.
        </p>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':500}"
          name="Impacto Social"
          vid="socialImpact"
        >
          <v-textarea
            v-model.trim="model.socialImpact"
            :error-messages="errors"
            counter="500"
            dense
            full-width
            outlined
            required
            single-line
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter v-bind="props" :value="countWords(model.socialImpact)"></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

        <p>
          <strong>29. Impacto ambiental * (500 palabras)</strong><br>
          Explicar de qué manera la propuesta apuesta a la preservación del medio ambiente, reducción de emisiones
          contaminantes, descarbonización, etc.
        </p>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':500}"
          name="Impacto ambiental"
          vid="environmentalImpact"
        >
          <v-textarea
            v-model.trim="model.environmentalImpact"
            :error-messages="errors"
            counter="500"
            dense
            full-width
            outlined
            required
            single-line
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter v-bind="props" :value="countWords(model.environmentalImpact)"></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

        <p>
          <strong>30. Impacto financiero * (500 palabras)</strong><br>
          Explicar el impacto que trae financieramente la propuesta en un lapso de 5 años. Adjuntar link en el punto 31
          que lleve a soporte donde se demuestre éxito financiero, flujo de caja generado, tasa interna de retorno
          (TIR), tiempo de retorno y otros indicadores. Se debe, adicionalmente, diligenciar el formato de información
          financiera que podrá descargar haciendo <a target="_blank" href="https://premiofisealainnovacion.fise.co/wp-content/uploads/2023/03/Modelo-evaluacion-financiera-Premio-FISE.xlsm">clic aquí.</a> Una vez lleno, por favor pegue el link con dicho formato en
          el punto 32.
        </p>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':500}"
          name="Impacto financiero"
          vid="financialImpact"
        >
          <v-textarea
            v-model.trim="model.financialImpact"
            :error-messages="errors"
            counter="500"
            dense
            full-width
            outlined
            required
            single-line
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter v-bind="props" :value="countWords(model.financialImpact)"></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

      </v-form>
    </ValidationObserver>
    <v-btn
      class="elevation-0 text-none"
      color="primary"
      @click="$emit('nextStep', 4)"
      dark
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="#ffc200"
      @click="save()"
      dark
    >Guardar avance
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      @click="next"
      dark
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        target="_blank"
        :href=termsAndConditionsUrl
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL, TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepFive',
  async created() {
    eventHub.$on('step5', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => { await this.getStep5() })
    })
  },

  data() {
    return {
      awardProposalId: null,
      model: {
        awardProposalId: null,
        environmentalImpact: '',
        financialImpact: '',
        socialImpact: ''
      },
      saveProgress: false,
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },

  computed: {
    isSaveProgress() {
      return this.saveProgress
    }
  },

  methods: {
    async getStep5() {
      if (this.awardProposalId) {
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-5/${this.awardProposalId}`)
        this.model.environmentalImpact = response.data.environmentalImpact ? response.data.environmentalImpact : ''
        this.model.financialImpact = response.data.financialImpact ? response.data.financialImpact : ''
        this.model.socialImpact = response.data.socialImpact ? response.data.socialImpact : ''
      }
    },

    async next() {
      this.saveProgress = false
      this.$nextTick(() => {
        this.submit()
      })
    },

    async save() {
      this.saveProgress = true
      this.$nextTick(() => {
        this.submit()
      })
    },

    async submit() {
      let result = false
      if (!this.saveProgress) {
        result = await this.$refs.form.validate()
      } else {
        result = true
      }
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.post(`${AWARD_PROPOSALS_URL}/step-5?saveProgress=${this.saveProgress}`, this.model)
          .then(() => {
            this.hideLoader()
            if (!this.saveProgress) {
              this.$emit('nextStep', 6)
            } else {
              this.showSuccess('Avance guardado correctamente!')
            }
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },

    countWords (text) {
      return text === undefined ? '' : text.trim().split(/[\s\n ]+/).length
    }
  },

  mixins: [loaderMixin, crudServiceMixin, notificationMixin]
}
</script>
