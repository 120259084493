<template>
  <div class="no-gutter justify-center">
    <div class="text-center mt-12">
      <img :src="iconOk" alt="iconOk"/>
      <p>
        <span style="font-size: 20px; display: block">
          <strong> Tu propuesta ha sido enviada exitosamente. </strong>
        </span>
        Si requieres hacer alguna modificación, por favor comunícate con nosotros.
      </p>
      <v-btn
        elevation="0"
        @click="goToAwardProposals"
        class="text-none "
        color="primary"
      >
        Regresar a la página de propuestas
      </v-btn>
    </div>
  </div>
</template>

<script>
import iconOk from '@/assets/images/icon-ok.jpg'

export default {
  name: 'SuccessfulProposal',

  data: function () {
    return {
      iconOk: iconOk
    }
  },

  methods: {
    goToAwardProposals() {
      this.$router.push({ name: 'awardProposals' })
    }
  }
}
</script>
