<template>
  <div>
    <p>
      <strong>{{ proposal.legalInfo.name }}</strong
      >, identificado con
      <strong>{{ proposal.legalInfo.identificationType }}</strong>
      <strong> {{ proposal.legalInfo.identification }}</strong> representante
      legal de la empresa <strong>{{ proposal.legalInfo.companyName }}</strong> con NIT:
      <strong>{{ proposal.businessIdentification }}</strong>
      , hace constar que el trabajo presentado cumple con los requisitos
      solicitados para participar en el Premio FISE a la Innovación 2023 y que
      entiende y acepta los términos y condiciones de este evento, habiendo
      tomado las medidas necesarias para poder adquirir este compromiso ante
      FISE en nombre de todas las personas e instituciones involucradas.
    </p>
    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">
        <ValidationProvider
          name="Aceptación representante legal"
          vid="legalConfirmation"
          v-slot="{ errors }"
          :rules="{ required: { allowFalse: false } }"
        >
          <v-checkbox
            v-model="model.legalConfirmation"
            :error-messages="errors"
          >
            <div slot="label">Aceptación representante legal</div>
          </v-checkbox>
        </ValidationProvider>

        <ValidationProvider
          name="Aceptación de términos y condiciones"
          vid="acceptTerms"
          v-slot="{ errors }"
          :rules="{ required: { allowFalse: false } }"
        >
          <v-checkbox
            class="mb-4 mt-0"
            v-model="model.acceptTerms"
            :error-messages="errors"
          >
            <div slot="label">
              Aceptación de
              <a
                @click.stop
                :href=termsAndConditionsUrl
                target="_blank"
              >
                <strong>
                  términos y condiciones del premio FISE a la innovación
                </strong>
              </a>
            </div>
          </v-checkbox>
        </ValidationProvider>
      </v-form>
    </ValidationObserver>

    <v-btn
      class="elevation-0 text-none"
      color="primary"
      @click="$emit('nextStep', 6)"
      dark
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      @click="submit"
      dark
    >Finalizar y enviar propuesta
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        target="_blank"
        :href=termsAndConditionsUrl
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL, TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'LegalRepresentativeDeclaration',
  async created() {
    eventHub.$on('step7', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => {
        await this.getStep7()
      })
    })
  },

  data() {
    return {
      awardProposalId: null,
      model: {
        innovationId: null,
        awardProposalId: null,
        legalConfirmation: false,
        acceptTerms: false
      },
      proposal: {
        legalInfo: {},
        businessName: '',
        businessIdentification: ''
      },
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },
  methods: {
    async getStep7() {
      if (this.awardProposalId) {
        const response = await this.get(
          AWARD_PROPOSALS_URL + '/step-7/' + this.awardProposalId
        )
        this.proposal = response.data
      }
    },

    async submit() {
      const result = await this.$refs.form.validate()
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.post(AWARD_PROPOSALS_URL + '/step-7', this.model)
          .then(() => {
            this.hideLoader()
            this.$router.push({ name: 'awardProposals' })
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },
  },
  mixins: [loaderMixin, crudServiceMixin,notificationMixin],
}
</script>
