<template>
  <div>
    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">
        <ValidationProvider
          v-slot="{ errors }"
          name="Link - Soportes impacto financiero"
          rules="required"
          vid="financialImpactLink"
        >
          <small class="text-muted">
            Adjuntar en este campo, los siguientes enlaces de respaldo del proyecto y los adicionales que considere pertinentes.
          </small>
          <v-text-field
            v-model.trim="model.financialImpactLink"
            :error-messages="errors"
            class="mt-1"
            dense
            label="31. Link - Soportes impacto financiero *"
            outlined
          ></v-text-field>
        </ValidationProvider>

        <a class="mt-4 mb-4" 
        href="https://premiofisealainnovacion.fise.co/wp-content/uploads/2023/03/Modelo-evaluacion-financiera-Premio-FISE.xlsm" 
        target="_blank">Descargar Formato Excel aquí</a>
        <ValidationProvider
          v-slot="{ errors }"
          name="Formato información financiera en Excel"
          rules="required"
          vid="financialExcelLink"
        >
          <v-text-field
            v-model.trim="model.financialExcelLink"
            :error-messages="errors"
            dense
            label="32. Formato información financiera en Excel *"
            outlined
          ></v-text-field>
        </ValidationProvider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
              v-slot="{ errors }"
              name="Link - Vídeo"
              rules="required"
              vid="videoLink"
            >
              <v-text-field
                v-model.trim="model.videoLink"
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                dense
                label="33. Link - Vídeo *"
                outlined
              ></v-text-field>
            </ValidationProvider>
          </template>
          <span>
            Enlace a un video con una duración máxima de 5 minutos que explique o amplie la información más relevante solicitada en el formulario (el video debe estar subido en YouTube y puede estar no listado para que solo sea visible para el administrador del canal y quien tenga el enlace de acceso).
          </span>
        </v-tooltip>

        <v-layout class="mt-4 mb-5" wrap>
          <v-card
            class="rounded-0 elevation-0"
            color="yellow"
            height="30"
            width="10"
          ></v-card>
          <strong class="primary--text ml-2 pt-1">
            Otros Soportes
          </strong>
        </v-layout>

        <ValidationProvider
          name="Link adicional No. 1"
          vid="additionalLink1"
        >
          <v-text-field
            v-model.trim="model.additionalLink1"
            dense
            label="34. Link adicional No. 1"
            outlined
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          name="Link adicional No. 2"
          vid="additionalLink2"
        >
          <v-text-field
            v-model.trim="model.additionalLink2"
            dense
            label="35. Link adicional No. 2"
            outlined
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          name="Link adicional No. 3"
          vid="additionalLink3"
        >
          <v-text-field
            v-model.trim="model.additionalLink3"
            dense
            label="36. Link adicional No. 3"
            outlined
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          name="Link adicional No. 4"
          vid="additionalLink4"
        >
          <v-text-field
            v-model.trim="model.additionalLink4"
            dense
            label="37. Link adicional No. 4"
            outlined
          ></v-text-field>
        </ValidationProvider>

      </v-form>
    </ValidationObserver>

    <v-btn
      class="elevation-0 text-none"
      color="primary"
      @click="$emit('nextStep', 5)"
      dark
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="#ffc200"
      @click="save"
      dark
    >Guardar avance
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      @click="next"
      dark
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        target="_blank"
        :href=termsAndConditionsUrl
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL,TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepSix',
  async created() {
    eventHub.$on('step6', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => { await this.getStep6() })
    })
  },

  data() {
    return {
      awardProposalId: null,
      model: {
        innovationId: null,
        financialImpactLink: '',
        financialExcelLink: '',
        videoLink: '',
        additionalLink1: '',
        additionalLink2: '',
        additionalLink3: '',
        additionalLink4: ''
      },
      saveProgress: false,
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },

  computed: {
    isSaveProgress() {
      return this.saveProgress
    }
  },

  methods: {
    async getStep6() {
      if (this.awardProposalId) {
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-6/${this.awardProposalId}`)
        this.model = response.data
      }
    },

    async next() {
      this.saveProgress = false
      this.$nextTick(() => {
        this.submit()
      })
    },

    async save() {
      this.saveProgress = true
      this.$nextTick(() => {
        this.submit()
      })
    },

    async submit() {
      let result = false
      if (!this.saveProgress) {
        result = await this.$refs.form.validate()
      } else {
        result = true
      }
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.post(`${AWARD_PROPOSALS_URL}/step-6?saveProgress=${this.saveProgress}`, this.model)
          .then(() => {
            this.hideLoader()
            if (!this.saveProgress) {
              this.$emit('nextStep', 7)
            }else{
              this.showSuccess('Avance guardado correctamente!')
            }
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    }
  },

  mixins: [loaderMixin, crudServiceMixin, notificationMixin]
}
</script>
