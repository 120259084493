<template>
  <div>
    <v-layout class="mt-4 mb-5" wrap>
      <v-card
        class="rounded-0 elevation-0"
        color="#ffc200"
        height="30"
        width="10"
      ></v-card>
      <strong class="primary--text ml-2 pt-1">
        Líder del equipo
      </strong>
    </v-layout>

    <p>
      Esta persona es la representante, desde la perspectiva técnica, del
      trabajo de innovación inscrito. En caso que el trabajo sea nominado, el
      líder, en conjunto con otro miembro de equipo, será el responsable de la
      defensa del trabajo ante el jurado. Será contactado, si es necesario, para
      la solicitud de aclaraciones y, en caso de que su trabajo sea seleccionado
      como ganador, recibirá en nombre del equipo innovador el estímulo en
      dinero del Premio FISE.
    </p>

    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">
        <ValidationProvider
          v-slot="{ errors }"
          name="Nombre"
          :rules="{'required':!isSaveProgress}"
          vid="name"
        >
          <v-text-field
            v-model="model.name"
            :error-messages="errors"
            dense
            label="16. Nombre *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="Tipo de documento"
              :rules="{'required':!isSaveProgress}"
              vid="identificationType"
            >
              <v-select
                v-model="model.identificationType"
                :error-messages="errors"
                :items="identificationTypes"
                dense
                item-text="description"
                item-value="code"
                label="17. Tipo de documento *"
                outlined
                type="text"
              ></v-select>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" sm="9">
            <ValidationProvider
              v-slot="{ errors }"
              name="Número de documento"
              :rules="{'required':!isSaveProgress}"
              vid="identification"
            >
              <v-text-field
                v-model="model.identification"
                :error-messages="errors"
                dense
                label="18. Número de documento de identidad *"
                outlined
                type="text"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <ValidationProvider
          v-slot="{ errors }"
          name="Teléfono móvil"
          :rules="{'required':!isSaveProgress}"
          vid="mobilePhone"
        >
          <vue-tel-input-vuetify
            v-model="model.mobilePhone"
            :error-messages="errors"
            :inputOptions="{ showDialCode: false }"
            :preferred-countries="['CO', 'US']"
            class="flex-wrap"
            dense
            label="19. Teléfono móvil *"
            mode="international"
            outlined
            type="text"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Correo electrónico"
          :rules="{'required':!isSaveProgress,'email':!isSaveProgress}"
          vid="email"
        >
          <v-text-field
            v-model="model.email"
            :error-messages="errors"
            dense
            label="20. Correo electrónico *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Profesión"
          :rules="{'required':!isSaveProgress}"
          vid="profession"
        >
          <v-text-field
            v-model="model.profession"
            :error-messages="errors"
            dense
            label="21. Profesión *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Experiencia"
          :rules="{'required':!isSaveProgress,'maxWords':100}"
          vid="experience"
        >
          <v-textarea
            v-model.trim="model.experience"
            :error-messages="errors"
            counter="100"
            dense
            label="22. Experiencia * (Máx. 100 palabras)"
            outlined
            rows="2"
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter
                v-bind="props"
                :value="
                  model.experience === undefined ? '' : model.experience.trim().split(/[\s\n ]+/).length
                "
              ></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

        <v-layout class="mt-4 mb-5" wrap>
          <v-card
            class="rounded-0 elevation-0"
            color="yellow"
            height="30"
            width="10"
          ></v-card>
          <strong class="primary--text ml-2 pt-1">
            Integrantes del equipo
          </strong>
        </v-layout>

        <div
          v-for="(member, index) in model.teamMembers"
          :key="index"
          class="d-flex flex-row w-100"
        >
          <div class="flex-fill row">
            <div class="col col-12 col-md-6 py-0">
              <validation-provider
                v-slot="{ errors }"
                :vid="`name${index}`"
                name="Nombre"
                :rules="{'required':!isSaveProgress}"
              >
                <v-text-field
                  v-model="member.name"
                  :error-messages="errors"
                  dense
                  label="Nombre Completo *"
                  outlined
                  type="text"
                ></v-text-field>
              </validation-provider>
            </div>

            <div class="col col-12 col-md-6 py-0">
              <validation-provider
                v-slot="{ errors }"
                :vid="`profession${index}`"
                name="Profesión"
                :rules="{'required':!isSaveProgress}"
              >
                <v-text-field
                  v-model="member.profession"
                  :error-messages="errors"
                  dense
                  label="Profesión *"
                  outlined
                  type="text"
                ></v-text-field>
              </validation-provider>
            </div>

            <div class="col col-12 py-0">
              <ValidationProvider
                v-slot="{ errors }"
                :vid="`experience${index}`"
                name="Experiencia"
                :rules="{'required':!isSaveProgress,'maxWords':100}"
              >
                <v-textarea
                  v-model.trim="member.experience"
                  :error-messages="errors"
                  class="mb-5"
                  counter="100"
                  dense
                  label="Experiencia * (Máx. 100 palabras)"
                  outlined
                  rows="2"
                  type="text"
                >
                  <template v-slot:counter="{ props }">
                    <v-counter
                      v-bind="props"
                      :value="
                        member.experience === undefined ? '' : member.experience.trim().split(/[\s\n ]+/).length
                      "
                    ></v-counter>
                  </template>
                </v-textarea>
              </ValidationProvider>
            </div>
          </div>

          <div class="ml-2 mr-0 mt-6">
            <v-btn
              class="elevation-0"
              color="primary"
              small
              @click="deleteMember(index)"
            >
              <v-icon center color="white">
                fa fa-trash
              </v-icon>
            </v-btn>
          </div>
        </div>

        <v-btn
          class="elevation-0 text--primary text-none mb-2"
          color="secondary"
          dark
          @click="addMember"
        >Agregar
        </v-btn>
      </v-form>
    </ValidationObserver>

    <v-btn
      class="elevation-0 text-none"
      color="primary"
      dark
      @click="$emit('nextStep', 1)"
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="#ffc200"
      dark
      @click="save"
    >Guardar avance
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      dark
      @click="next"
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        :href=termsAndConditionsUrl
        target="_blank"
      >Términos y condiciones</a> 
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL, IDENTIFICATION_TYPES_URL,TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepTwo',
  async created() {
    eventHub.$on('step2', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => {
        await this.getIdentificationTypes()
        await this.getStep2()
      })
    })
  },

  computed: {
    isSaveProgress() {
      return this.saveProgress
    }
  },

  data() {
    return {
      awardProposalId: null,
      identificationTypes: [],
      model: {
        awardProposalId: null,
        experience: '',
        name: '',
        identificationType: '',
        identification: '',
        mobilePhone: '',
        email: '',
        profession: '',
        teamMembers: []
      },
      saveProgress: false,
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },

  methods: {
    async getStep2() {
      if (this.awardProposalId) {
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-2/${this.awardProposalId}`)
        if (response.data && response.data.id) {
          this.model = response.data
        }
      }
    },

    async getIdentificationTypes() {
      const response = await this.get(IDENTIFICATION_TYPES_URL)
      this.identificationTypes = response.data
    },

    async save() {
      this.saveProgress = true
      this.$nextTick(() => {
        this.submit()
      })
    },

    async next() {
      this.saveProgress = false
      this.$nextTick(() => {
        this.submit()
      })
    },

    async submit() {
      let result = await this.$refs.form.validate()
      if (!this.saveProgress) {
        result = await this.$refs.form.validate()
      } else {
        result = true
      }
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.post(`${AWARD_PROPOSALS_URL}/step-2?saveProgress=${this.saveProgress}`, this.model)
          .then(() => {
            this.hideLoader()
            if (!this.saveProgress) {
              this.$emit('nextStep', 3)
            } else {
              this.showSuccess('Avance guardado correctamente!')
            }
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },

    addMember() {
      this.model.teamMembers.push({
        experience: '',
        name: '',
        profession: ''
      })
    },

    deleteMember(position) {
      this.model.teamMembers = this.model.teamMembers.filter((item, index) => index !== position)
    }
  },

  mixins: [loaderMixin, crudServiceMixin, notificationMixin]
}
</script>
