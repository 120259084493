<template>
  <div>
    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">

        <p class="font-bold">
          NOTA: Si desea suspender la redacción, dé clic en "Guardar avance" antes
          de abandonar el formulario para que su avance sea registrado.
          Mientras no haya enviado su propuesta, siempre puede volver al paso anterior
          para editar o continuar escribiendo.
        </p>
        <p>
          <strong>26. Aplicabilidad en el mercado * (1.000 palabras)</strong><br>
          En este criterio se debe evidenciar y sustentar de qué manera tiene aplicabilidad el proyecto en el mercado
          según aplique la línea de acción (ver TDR), señalando la cantidad monetaria ganada o proyectada y cómo se
          ejecuta para lograrlo, su escalabilidad y el potencial que representa. Ejemplo: Tamaño de mercado, número de
          ventas nacionales y por exportaciones, porcentaje del mercado capturado, crecimiento anual, etc.
        </p>

        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':1000}"
          name="Aplicabilidad en el mercado"
          vid="applicability"
        >
          <v-textarea
            v-model.trim="model.applicability"
            :error-messages="errors"
            counter="1000"
            dense
            full-width
            outlined
            required
            single-line
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter v-bind="props" :value="countWords(model.applicability)"></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

        <p>
          <strong>27. Originalidad de la innovación * (1.000 palabras)</strong><br>
          Dar una descripción de la consistencia en innovación del proyecto donde se muestre, por ejemplo, análisis de
          competidores, registros de propiedad industrial, publicaciones técnicas, propuesta de valor, diferenciadores
          etc.
        </p>

        <ValidationProvider
          v-slot="{ errors }"
          :rules="{'required':!isSaveProgress,'maxWords':1000}"
          name="Originalidad de la innovación"
          vid="originality"
        >
          <v-textarea
            v-model.trim="model.originality"
            :error-messages="errors"
            counter="1000"
            dense
            full-width
            outlined
            required
            single-line
            type="text"
          >
            <template v-slot:counter="{ props }">
              <v-counter v-bind="props" :value="countWords(model.originality)"></v-counter>
            </template>
          </v-textarea>
        </ValidationProvider>

      </v-form>
    </ValidationObserver>

    <v-btn
      class="elevation-0 text-none"
      color="primary"
      dark
      @click="$emit('nextStep', 3)"
    >Anterior
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="#ffc200"
      dark
      @click="save"
    >Guardar avance
    </v-btn>

    <v-btn
      class="elevation-0 text--primary text-none ml-3"
      color="secondary"
      dark
      @click="next"
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        :href=termsAndConditionsUrl
        target="_blank"
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {AWARD_PROPOSALS_URL, TERMS_AND_CONDITIONS_URL} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import eventHub from '@/eventhub'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepFour',
  async created() {
    eventHub.$on('step4', async () => {
      this.awardProposalId = this.$route.params.id
      await this.executeWithLoader(async () => {
        await this.getStep4()
      })
    })
  },

  data() {
    return {
      awardProposalId: null,
      model: {
        awardProposalId: null,
        applicability: '',
        originality: ''
      },
      saveProgress: false,
      termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
    }
  },

  computed: {
    isSaveProgress() {
      return this.saveProgress
    }
  },

  methods: {
    async getStep4() {
      if (this.awardProposalId) {
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-4/${this.awardProposalId}`)
        this.model.applicability = response.data.applicability ? response.data.applicability : ''
        this.model.originality = response.data.originality ? response.data.originality : ''
      }
    },

    async next() {
      this.saveProgress = false
      this.$nextTick(() => {
        this.submit()
      })
    },

    async save() {
      this.saveProgress = true
      this.$nextTick(() => {
        this.submit()
      })
    },

    async submit() {
      let result = false
      if (!this.saveProgress) {
        result = await this.$refs.form.validate()
      } else {
        result = true
      }
      if (result) {
        this.showLoader()
        this.model.awardProposalId = this.awardProposalId
        this.$axios
          .post(`${AWARD_PROPOSALS_URL}/step-4?saveProgress=${this.saveProgress}`, this.model)
          .then(() => {
            if (!this.saveProgress) {
              this.$emit('nextStep', 5)
            } else {
              this.showSuccess('Avance guardado correctamente!')
            }
            this.hideLoader()
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },

    countWords (text) {
      return text === undefined ? '' : text.trim().split(/[\s\n ]+/).length
    }
  },

  mixins: [loaderMixin, crudServiceMixin, notificationMixin]
}
</script>
