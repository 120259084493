<template>
  <div>

    <p class="text-justify">
      El Premio va dirigido a innovaciones que impacten positivamente la industria eléctrica y los servicios asociados a
      esta. Los trabajos deben ser presentados por empresas matriculadas en Colombia, con personería jurídica legalmente
      constituida en el país o por grupos y centros de investigación, desarrollo tecnológico o innovación con
      reconocimiento vigente de COLCIENCIAS o de una entidad educativa que los avale; así mismo, puede participar
      cualquier tipo de alianza o consorcio entre estos actores. Una vez efectuada la aplicación online, la organización
      del Premio entenderá que el representante legal (o quien haga sus veces) de la empresa proponente, o el director
      del grupo de investigación, conoce y acepta los términos de participación en el mismo. Se presumirá además el
      consentimiento de las demás personas, empresas e instituciones involucradas en la alianza o consorcio que
      desarrolló la innovación, garantizado esto por el representante legal proponente (o el director del grupo de
      investigación). Tenga en cuenta, en el campo 2, ingresar los nombres como deben aparecer en los medios de
      difusión. En caso de ser una alianza, designe como “proponente” al representante legal de una de las empresas
      miembros de la misma (o al director del grupo de investigación).
    </p>

    <strong>
      NOTA: Para que se genere el borrador de tu propuesta es necesario que diligencies todos los campos de esta página
      y des clic en SIGUIENTE.
    </strong>

    <p class="red--text">Los campos marcados con asterisco <strong>*</strong> son obligatorios.</p>

    <ValidationObserver ref="form">
      <v-form @submit.prevent="submit">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
              v-slot="{ errors }"
              name="Nombre empresa"
              rules="required"
              vid="businessName"
            >
            <small class="text-muted">
              Nombre de la entidad/consorcio como debe aparecer en notas de
              prensa, el sitio web de FISE y otros medios.
            </small>  
              
              <v-text-field
                v-model="model.businessName"
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                dense
                label="1. Nombre de la empresa, grupo o consorcio *"
                outlined
                type="text"
              ></v-text-field>
            </ValidationProvider>
          </template>
        </v-tooltip>

        <ValidationProvider vid="groupOfCompanies">
          <v-text-field
            v-model="model.groupOfCompanies"
            dense
            label="2. En caso de ser una alianza o consorcio, indique las empresas e instituciones que lo conforman:"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
              v-slot="{ errors }"
              name="NIT empresa"
              rules="required"
              vid="businessIdentification"
            >
            <small class="text-muted">
              Si es una alianza o consorcio, indique el NIT de la empresa a al que
              pertenece el representante legal que fungirá como "proponente" de la
              innovación.
            </small>
              <v-text-field
                v-model="model.businessIdentification"
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                dense
                label="3. NIT de la empresa *"
                outlined
                type="text"
              ></v-text-field>
            </ValidationProvider>
          </template>
         </v-tooltip>

        <ValidationProvider
          v-slot="{ errors }"
          name="Departamento"
          rules="required"
          vid="department"
        >
          <v-select
            v-model="model.departmentId"
            :error-messages="errors"
            :items="departments"
            dense
            item-text="name"
            item-value="id"
            label="4. Departamento *"
            outlined
            type="text"
            @change="getCities"
          ></v-select>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Ciudad"
          rules="required"
          vid="city"
        >
          <v-select
            v-model="model.cityId"
            :error-messages="errors"
            :items="cities"
            dense
            item-text="name"
            item-value="id"
            label="4.1. Ciudad *"
            outlined
            type="text"
          ></v-select>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Dirección"
          rules="required"
          vid="address"
        >
          <v-text-field
            v-model="model.address"
            :error-messages="errors"
            dense
            label="5. Dirección *"
            outlined
            required
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Sitio web"
          vid="webSite"
        >
          <v-text-field
            v-model="model.webSite"
            :error-messages="errors"
            dense
            label="6. Sitio web"
            outlined
            required
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Redes social Facebook"
          vid="facebookSocialMedia"
        >
          <v-text-field
            v-model="model.facebookSocialMedia"
            :error-messages="errors"
            dense
            label="7. Redes sociales - Facebook (escriba la URL del perfil de la red social)"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Redes social Twitter"
          vid="twitterSocialMedia"
        >
          <v-text-field
            v-model="model.twitterSocialMedia"
            :error-messages="errors"
            dense
            label="7.1 Redes sociales - Twitter (escriba la URL del perfil de la red social)"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Redes social LinkedIn"
          vid="linkedInSocialMedia"
        >
          <v-text-field
            v-model="model.linkedInSocialMedia"
            :error-messages="errors"
            dense
            label="7.2 Redes sociales - LinkedIn (escriba la URL del perfil de la red social)"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Redes social YouTube"
          vid="youtubeSocialMedia"
        >
          <v-text-field
            v-model="model.youtubeSocialMedia"
            :error-messages="errors"
            dense
            label="7.3 Redes sociales - YouTube (escriba la URL del perfil de la red social)"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Correo electrónico"
          rules="required|email"
          vid="businessEmail"
        >
          <v-text-field
            v-model="model.businessEmail"
            :error-messages="errors"
            dense
            label="8. Correo electrónico de la empresa *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Teléfono"
          rules="required"
          vid="phone"
        >
          <vue-tel-input-vuetify
            v-model="model.phone"
            :error-messages="errors"
            :inputOptions="{ showDialCode: false }"
            :preferred-countries="['CO', 'US']"
            class="flex-wrap"
            dense
            label="9. Teléfono *"
            mode="international"
            outlined
            type="text"
          />
        </ValidationProvider>

        <v-layout class="mt-4 mb-5" wrap>
          <v-card
            class="rounded-0 elevation-0"
            color="yellow"
            height="30"
            width="10"
          ></v-card>
          <strong class="primary--text ml-2 pt-1">
            Información sobre el PROPONENTE de la innovación
          </strong>
        </v-layout>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
              v-slot="{ errors }"
              name="Nombre del representante legal"
              rules="required"
              vid="name"
            >
            <small class="text-muted">
              Si se trata de varias empresas aliadas, sin un consorcio formal,
              elija el representante de una de ellas como proponente.
            </small>
              <v-text-field
                v-model="model.name"
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                dense
                label="10. Nombre del representante legal *"
                outlined
                type="text"
              ></v-text-field>
            </ValidationProvider>
          </template>
        </v-tooltip>

        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="Tipo de documento"
              rules="required"
              vid="identificationType"
            >
              <v-select
                v-model="model.identificationType"
                :error-messages="errors"
                :items="identificationTypes"
                dense
                item-text="description"
                item-value="code"
                label="11. Tipo de documento *"
                outlined
                type="text"
              ></v-select>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" sm="9">
            <ValidationProvider
              v-slot="{ errors }"
              name="Número de documento"
              rules="required"
              vid="identification"
            >
              <v-text-field
                v-model="model.identification"
                :error-messages="errors"
                dense
                label="12. Número de documento de identidad *"
                outlined
                type="text"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>

        <ValidationProvider
          v-slot="{ errors }"
          name="Teléfono móvil"
          rules="required"
          vid="mobilePhone"
        >
          <vue-tel-input-vuetify
            v-model="model.mobilePhone"
            :error-messages="errors"
            :inputOptions="{ showDialCode: false }"
            :preferred-countries="['CO', 'US']"
            class="flex-wrap"
            dense
            label="13. Teléfono móvil *"
            mode="international"
            outlined
            type="text"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Correo electrónico"
          rules="required|email"
          vid="email"
        >
          <v-text-field
            v-model="model.email"
            :error-messages="errors"
            dense
            label="14. Correo electrónico *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          name="Empresa del representante legal"
          rules="required"
          vid="companyName"
        >
          <v-text-field
            v-model="model.companyName"
            :error-messages="errors"
            dense
            label="15. Empresa a la que pertenece el representante legal *"
            outlined
            type="text"
          ></v-text-field>
        </ValidationProvider>
      </v-form>
    </ValidationObserver>

    <v-btn
      class="elevation-0 text--primary text-none"
      color="secondary"
      dark
      @click="submit"
    >Siguiente
    </v-btn>

    <p class="my-3 text-center">
      <a
        class="headline"
        :href="termsAndConditionsUrl"
        target="_blank"
      >Términos y condiciones</a>
    </p>
  </div>
</template>
<script>
import {
  AWARD_PROPOSALS_URL,
  CITIES_URL,
  DEPARTMENTS_URL,
  IDENTIFICATION_TYPES_URL,
  TERMS_AND_CONDITIONS_URL
} from '@/constants/ServicesConstants'
import eventHub from '@/eventhub'
import loaderMixin from '@/mixins/loaderMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  name: 'StepOne',
  mixins: [loaderMixin, crudServiceMixin, notificationMixin],

  async created() {
    eventHub.$on('step1', async () => {
      this.awardProposalId = this.$route.params.id
      try {
        this.showLoader()
        await Promise.all([
          this.getDepartments(),
          this.getIdentificationTypes(),
        ]).then(async () => {
          await this.getStep1()
          this.hideLoader()
        })
      } catch (e) {
        this.hideLoader()
        this.showError()
      }
    })
  },

  data: () => ({
    awardProposalId: null,
    cities: [],
    departments: [],
    identificationTypes: [],
    model: {
      address: '',
      businessEmail: '',
      businessIdentification: '',
      businessName: '',
      cityId: '',
      companyName: '',
      departmentId: '',
      email: '',
      groupOfCompanies: '',
      identification: '',
      identificationType: '',
      mobilePhone: '',
      name: '',
      phone: '',
      facebookSocialMedia: '',
      twitterSocialMedia: '',
      linkedInSocialMedia: '',
      youtubeSocialMedia: '',
      webSite: '',
    },
    termsAndConditionsUrl: TERMS_AND_CONDITIONS_URL
  }),

  methods: {
    async getDepartments() {
      const response = await this.get(`${DEPARTMENTS_URL}/${47}`)
      this.departments = response.data
    },

    getCities() {
      this.cities = []
      this.showLoader()
      this.get(`${CITIES_URL}/${this.model.departmentId}`)
        .then((response) => {
          this.cities = response.data
          this.hideLoader()
        })
        .catch(() => {
          this.showError()
        })
    },

    async getStep1() {
      if (this.awardProposalId) {
        await this.getDepartments();
        const response = await this.get(`${AWARD_PROPOSALS_URL}/step-1/${this.awardProposalId}`)
        this.model = response.data
        await this.getCities(this.model.departmentId)
      }
    },

    async getIdentificationTypes() {
      const response = await this.get(IDENTIFICATION_TYPES_URL)
      this.identificationTypes = response.data
    },

    async submit() {
      const result = await this.$refs.form.validate()
      if (result) {
        this.showLoader()

        if (this.awardProposalId) {
          this.model.id = this.awardProposalId
        }

        this.post(`${AWARD_PROPOSALS_URL}/step-1`, this.model)
          .then((response) => {
            this.hideLoader()
            const awardProposalId = response.data
            if (!this.awardProposalId) {
              this.$router.push({ name: 'editAwardProposal', params: { id: awardProposalId } }).then(() => {
                this.$emit('nextStep', 2)
              })
            } else {
              this.$emit('nextStep', 2)
            }
          })
          .catch((error) => {
            this.hideLoader()
            if (error && error.response && error.response.status === 422) {
              this.invalidFields()
              this.$refs.form.setErrors(error.response.data)
            } else {
              this.showError()
            }
          })
      }
    },
  },
};
</script>
